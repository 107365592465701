<template>
  <main>
    <div class="container">
      <form class="form-horizontal mb20">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel">
              <div class="panel-heading">
                <h2 class="pageTitle">
                  パスワード変更完了<span>complete</span>
                </h2>
              </div>
              <div class="panel-body">
                <div class="row mb30">
                  <div class="col-sm-12 center completeM">
                    <div>
                      <img src="asset/img/common/img-checkmark.png">
                    </div>
                    Complete!<br>
                    <span>新しいパスワードの設定が完了しました。下記よりログインしてください。</span>
                  </div>
                </div>
                <ul class="actionVertical clearfix">
                  <li>
                    <router-link :to="{name: 'login'}" class="btn btn01">
                      ログイン画面へ
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ResetPasswordSuccess'
}
</script>

<style scoped>

</style>
